body {
  /* rgb(62, 35, 35) 100%); */
 /* background: linear-gradient(90deg, rgba(12, 10, 10, 0.89) 0%, rgba(22, 14, 14, 0.946) 100%); */
 background: white;
}

.card {
  /* border-radius: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17); */
  padding: 20px;
  margin: 10px;
  text-align: center;
  max-width: 100%;
  /* display: inline-block; */
  align-content: center;
}

.card img {
  max-width: 60%;
  height: auto;
  margin-bottom: 10px;
  animation-name: fade-img;
  animation-duration: 2s;
}

.card p {
  font-family: Arial, sans-serif;
  /* color: rgb(196, 192, 192); */
  color: rgb(15, 15, 15);
  
}

.card h1 {
  margin: 10px;
}

.timeline {
  position: relative;
  max-width: 1200px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin: 100px auto;
}

.container {
  padding: 10px 50px !important;
  position: relative;
  width: 50%;
  /* background-color: rgba(0, 0, 0, 0.3); */
  text-align: left !important;
  animation: movedown 1s linear forwards;
  opacity: 0;
}

@keyframes movedown {
  0%{
    opacity: 1;
    transform: translateY(-30px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}

.container:nth-child(1) {
  animation-delay: 0s;
}

.container:nth-child(2) {
  animation-delay: 1s;
}

.container:nth-child(3) {
  animation-delay: 2s;
}

.container:nth-child(4) {
  animation-delay: 3s;
}

.container:nth-child(5) {
  animation-delay: 4s;
}

.text-box {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 20px;
  /* border: solid rgba(255, 243, 9, 0.675); */
  /* background: linear-gradient(90deg, rgba(101, 30, 30, 0.782) 0%,rgb(33, 33, 26) 100%); */
  /* background:  rgba(207, 11, 11, 0.844); */
  position: relative;
  border-radius: 6px;
  font-size: 15px;
  /* box-shadow: 0 6px 20px rgba(255, 217, 0, 0.17); */
  box-shadow: 0 0 10px rgba(77, 2, 2, 0.383);

}

.left-container {
  left: 0;
}

.right-container {
  left: 50%;
}

.container img {
  position: absolute;
  width: 40px;
  border-radius: 50%;
  right: -20px;
  top: 32px;
  z-index: 10;
}

.right-container img {
  left: -20px ;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: rgba(63, 63, 63, 0.678);
  top: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1;
  animation: moveline 6s linear forwards;
}

@keyframes moveline {
  0%{
    height: 0;
  }
  100%{
    height: 100%;
  }
}

.text-box h3 {
  font-weight: 600 !important;
  /* color: rgb(242, 199, 9); */
  color: rgb(242, 9, 9);
}

.text-box small {
  display: inline-block;
  margin-bottom: 15px;
  /* color: rgb(196, 192, 192); */
  color: rgb(15, 15, 15);
}

.left-container-arrow {
  height: 0;
  width: 0;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  /* border-left: 15px solid rgba(93, 102, 1, 0.675); */
  border-left: 15px solid rgb(255, 0, 0);
  right: -15px;
}

.right-container-arrow {
  height: 0;
  width: 0;
  position: absolute;
  top: 28px;
  z-index: 1;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid rgb(255, 0, 0);
  left: -15px;
}

@media screen and (max-width: 600px) {
  .contactUs-content p{
    font-size: 58px !important;
  }
  .timeline{
    margin: 50px auto;
  }
  .timeline::after {
    left: 31px;
  }
  .container {
    width: 100%;
    padding-left: 80px !important;
    padding-right: 25px !important;
  }
  .text-box {
    font-size: 13px;
  }
  .text-box small {
    margin-bottom: 10px;
  }
  
  .right-container {
    left: 0;
  }
  .left-container img, .right-container img {
    left: 10px;
    top: 45px;
  }
  .left-container-arrow, .right-container-arrow{
    border-right: 15px solid rgb(255, 0, 0);;
    border-left: 0;
    left: -15px;
  }
  .mission-card,
  .vision-card {
    width: 90%; /* Adjust width for smaller screens */
  }


.mission-vision-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards horizontally */
}

}

.mission-vision-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center; /* Center cards horizontally */
}


.container h2 {
  text-align: center;
  font-family: Arial, sans-serif;
  color: rgb(72, 71, 71);
}

.card h2 {
  margin: 20px;
}

.mission-card hr, .vision-card hr, .card hr {
    /* border: 3px solid rgba(75, 75, 73, 0.392); */
    border: 3px solid rgb(255, 225, 0);
    border-radius: 5px;
    margin: 15px 40%;
}

.mission-text {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
  padding-left: 30px;
  /* border: solid rgba(19, 19, 19, 0.675); */
  border-left: solid red;
  box-shadow: 0 0 10px rgba(77, 2, 2, 0.383);
  position: relative;
  border-radius: 6px;
  font-size: 13px;
  /* box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17); */
  margin: 10px;
  text-align: left;
}

