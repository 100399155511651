.contactUs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  
}


.contactUs-img-container {
  position: relative;
  width: 100%;
  height: 600px; /* Set your desired height */
  background-image: url('/public/images/office.png');
  background-size: cover;
  background-position: center;
}

.contactUs-content {
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); Semi-transparent background */
  padding: 10px;
  color: white;
  /* max-width: 300px; Adjust as needed */
}
.contactUs-content p {
  font-size: 100px;
}

/* 
.contactUs-container .image-contact-us img{
  width: 1500px;
  height: 600px;
}
.contactUs-container .image-contact-us p{
 color: white;
 font-size: 48px;
 top: -100;
 z-index: 1;
} */

.contactUs-box {
  width: 100%; /* Adjust as needed */
  height: 250px;
  /* background-color: #3498db; */
  /* margin: 20px; */
  color: black;
  text-align: left;
  /* line-height: 200px; */
  font-size: 48px;
  
  padding: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.contactUs-container .contactUs-box:nth-child(2), .contactUs-box:nth-child(4) {
  background-color: rgba(241, 229, 61, 0.647) !important;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: slide 0.5s forwards;
    right: 0px;    
}

.contactUs-box p, a{
  font-size: 18px;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 768px) {
  .contactUs-box {
    width: 45%; /* Adjust for smaller screens */
    height: auto;
  }
  .contactUs-content p{
    font-size: 58px !important;
  }
}

@media only screen and (max-width: 480px) {
  .contactUs-box {
    width: 100%; /* Adjust for mobile devices */
    height: auto;
  }
  .contactUs-content p{
    font-size: 58px !important;
  }
  
}