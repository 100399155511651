video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

/* .hero-container {
  
  display: flex;
  align-items: center;
} */

.text-container {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.707);
  z-index: 10;
  position: absolute;
  width: 100%;
  height: 100%;
  color: #f4f0f0c8;
  font-size: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.image-container {
  width: 25%;
  height: 100vh;
  display: inline-block;
}

.hero-container div img {
  width:100%;
  height:100%;
  object-fit:cover;
}

.hero-container > h1 {
  color: #000000;
  font-size: 40px;
  /* margin-top: -100px; */
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 20px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}


