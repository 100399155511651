/* .carousel-container {
    max-width: 100%;
    max-height:100%;
}
.carousel-container img{ 
    max-width:100%;
    max-height:100%;
    object-fit: contain;
}

.carousel-text {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    max-width: 250px;
}

.carousel-text h3 {
    margin: 0;
    font-size: 1.2rem;
    
}

.carousel-text p {
    margin: 5px 0 0;
    font-size: 0.9rem;
} */


 .carousel-container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
 }

 .carousel-container .list .item {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
 }

 .carousel-container .list .item img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 0 0 0;
 }

 .carousel-container .list .item .content {
    position: absolute;
    top: 20%;
    width: 1140px;
    max-width: 80%;
    left: 50%;
    transform: translateX(-50%);
    padding-right: 30%;
    box-sizing: border-box;
    color: aliceblue;
    text-shadow: 0 5px 10px black;
 }

 .carousel-container .list .item .content .title {
    font-size: 5em;
    font-weight: bold;
    line-height: 1.3em;
 }

 .carousel-container .list .item .description {
    font-size: 14px;
 }

 .carousel-container .list .item .content .button {
    margin-top: 20px;
 }

 .carousel-container .list .item .content .button button {
    border: none;
    background-color: red;
    padding: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 2px;
    transition: 0.4s;
    color: wheat;
 }


 .carousel-container .list .item .content .button button:hover{
    background-color: yellow;
    color: black;
 }

 

.thumbnail {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: 90px;
    left: 30%;
    width: max-content;
    z-index: 100;
 }

.thumbnail .item {
    width: 140px;
    height: 150px;
    /* height: 220px; */
    flex-shrink: 0;
    position: relative;
    cursor: pointer;

    text-align: center;
    vertical-align: middle;
    padding-top: 20px;

    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    border: 1px solid rgba(241, 229, 61, 0.647);
 }

 .thumbnail .block{
    background:  rgba(241, 229, 61, 0.647) !important;  
 }
 .thumbnail .block p{
    color: black !important;  
 }
/* 
.thumbnail .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 5px 0 150px rgba(0, 0, 0, 0.3);
 } */


.thumbnail .item p{
 color: #fff;
}
 .carousel-container .list .item:nth-child(1) {
    z-index: 1;
 }

 .carousel-container .list .item:nth-child(1) .content .title,
 .carousel-container .list .item:nth-child(1) .content .description,
 .carousel-container .list .item:nth-child(1) .content .button {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent 0.5s 1s linear 1 forwards;
 }

 @keyframes showContent {
    to{
        transform: translateY(0px);
        filter: blur(00px);
        opacity: 1;
    }
}

.carousel-container .list .item:nth-child(1) .content .title {
    animation-delay: 0.4s;
}

.carousel-container .list .item:nth-child(1) .content .description {
    animation-delay: 0.6s;
}

.carousel-container .list .item:nth-child(1) .content .button {
    animation-delay: 0.8s;
}



@media screen and (max-width: 678px) {
    .carousel-container .list .item .content{
        padding-right: 0;
    }

    .carousel-container .list .item .content .title {
        font-size: 30px;
    }

    .carousel-container .thumbnail {
        display: flex;
        gap: 5px;
        position: absolute;
        bottom: 5px;
        left: 0%;
        width: 100%;
        height: auto;
        z-index: 100;    
    }

    .carousel-container .icon {
        padding-left: 5px;
    }

    .thumbnail .item {
        width: 60px;
        height: 110px;
    }
    .thumbnail .item p{
      font-size: 9px;
    }

    .fab {
        font-size: 25px;
    }

    .carousel-container .dialog-container {
        top: 50% !important;
        left: 50% !important;
        width: 100% !important;
    }

    .career-card-close-btn {
        width: 100%;
        padding: 10px;
        background-color: white;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        border: none;
        letter-spacing: 2px;
        font-family: "Poppins", sans-serif;
      }
    
      body.modal-open {
        overflow: hidden;
      }
    
      .career-card-close-btn:hover {
        background-color: red;
        color: white;
      }

    .carousel-container .contact-info div{
        padding: 5px;
    }
    .carousel-container .contact-info div .dialog-header{
        display: flex;
    }
    

    .carousel-container .contact-info div .social-icon-link:nth-child(2){
        margin-left: 130px;
    }

    .carousel-container .contact-info {
        flex: 1;
        font-size: 9px !important;
        font-weight: normal;
        /* animation: showThubmnail 0.5s linear 1 forwards; */
        /* animation: showContent 1s 1s linear 1 forwards; */
    }


    .carousel-container .contact-info p {
        flex: 1;
        font-size: 10px !important;
        font-weight: normal;
        /* animation: showThubmnail 0.5s linear 1 forwards; */
        /* animation: showContent 1s 1s linear 1 forwards; */
    }
    .carousel-container .contact-info a {
        flex: 1;
        font-size: 10px !important;
        font-weight: normal;
        text-decoration: none;
        color: black;
        /* animation: showThubmnail 0.5s linear 1 forwards; */
        /* animation: showContent 1s 1s linear 1 forwards; */
    }

    .carousel-container .dialog-content {
        display: flex !important;
        flex-direction: column !important;
        /* animation: showThubmnail 0.5s linear 1 forwards; */
    }

    /* .carousel-container .dialog-container .dialog-content .qr-code .qr {
        width: 10px !important;
        height: 80px !important;
    } */
    
    
}

.carousel-container .list .item:nth-child(1) img {
    width: 150px;
    height: 220px;
    position: absolute;
    bottom: 50px;
    left: 50%;
    border-radius: 30px;
    animation: showImage 0.5s linear 1 forwards;
}

@keyframes showImage {
    to{
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel-container .thumbnail .item {
    overflow: hidden;
    animation: showThubmnail 0.5s linear 1 forwards;
}

@keyframes showThubmnail {
 from{
    width: 0;
    opacity: 0;
 }
}

.carousel-container .dialog-container {
    position: fixed;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    padding: 10px ;
    z-index: 10;
    width: 40%;
    
    background-color: rgba(241, 3, 3, 0.231);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: slide 0.5s forwards;
    right: 0px;    
}

@keyframes slide {
  100% { right: 10px; }
}

.carousel-container .dialog-content {
    display: flex;
    flex-direction: row;
    /* animation: showThubmnail 0.5s linear 1 forwards; */
}

.carousel-container .contact-info {
    flex: 1;
    padding-right: 10px;
    font-size: 13px;
    font-weight: normal;
    /* animation: showThubmnail 0.5s linear 1 forwards; */
    /* animation: showContent 1s 1s linear 1 forwards; */
}

.carousel-container .contact-info a {
    font-size: 13px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    /* animation: showThubmnail 0.5s linear 1 forwards; */
    /* animation: showContent 1s 1s linear 1 forwards; */
}

.carousel-container .contact-info hr {
    border: 2px solid rgba(255, 225, 0, 0.353);
    border-radius: 5px;
}

.carousel-container .contact-info h3 {
    color: white;
}

.carousel-container .qr-code {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: showContent 1s 1s linear 1 forwards; */
}

.carousel-container .qr-code > div {
    width: 200px;
    height: 200px;
    /* animation: showContent 1s 1s linear 1 forwards; */
}

.carousel-container .contact-info div{
    padding-top: 5px;
    padding-bottom: 5px;
}
