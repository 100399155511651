.career-card-list {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin-left: 5%;
  
}

.career-card {
    background-color: #faf3f346;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    width: 300px; /* Adjust according to your design */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }

  .career-card:hover {
    background-color: rgba(241, 3, 3, 0.161);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  .career-card h3 {
    font-size: 18px;
    font-family: "Poppins", sans-serif;
  }

  .career-card h3::first-letter {
    color: red;
  }

  .career-card h5 {
    color: rgba(128, 128, 128, 0.347);
    font-size: 16px;
    margin-top: 5px;
    font-family: "Poppins", sans-serif;
  }

  .career-card-description {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .career-card-description p {
    font-family: "Poppins", sans-serif;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
  }

  .career-card hr {
    border: 1px solid rgba(5, 5, 5, 0.059);
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .career-card-btn {
    width: 100%;
    padding: 10px;
    background-color: transparent;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
    letter-spacing: 2px;
    font-family: "Poppins", sans-serif;
  }

  .career-card-close-btn {
    width: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
    letter-spacing: 2px;
    font-family: "Poppins", sans-serif;
  }

  body.modal-open {
    overflow: hidden;
  }

  .career-card-close-btn:hover {
    background-color: red;
    color: white;
  }

  .career-card-btn:hover {
    background-color: red;
    color: white;
  }
  
  @media (max-width: 768px) {
    .career-card {
      width: 100%; /* Make it full width on smaller screens */
    }
    .career-card-description p {
      max-width: 100%;
    }

    .career-card h5 {
      margin-top: 15px;
    }

    .dialog-content {
      display: flex !important;
      flex-direction: column !important;  
      /* animation: showThubmnail 0.5s linear 1 forwards; */
    }
    .dialog-container-careers {
      width: 90% !important;
    }
    .qr-code {
      width: 100% !important;
    }

    .qr-code p, h3 {
      font-size: 13px !important; 
    }

    .contact-info p, h3 {
      font-size: 13px !important;
    }
  
  }

.dialog-container-careers {
    top: 50% !important;
    left: 80% !important;
}
.dialog-container-careers {
  position: fixed;
  overflow-y: hidden !important;
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
  padding: 10px ;
  z-index: 10;
  width: 60%;
  height: auto;
  
  background-color: rgba(241, 3, 3, 0.341);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  animation: slide 0.5s forwards;
  right: 0px;

}

@keyframes slide {
100% { right: 10px; }
}
 .dialog-content {
  display: flex;
  flex-direction: row;
  /* animation: showThubmnail 0.5s linear 1 forwards; */
}


.qr-code {
  /* flex: 1;
  display: flex; */
  justify-content: center;
  align-items:flex-end;
  font-size: 14px;
  width: 50%;

  flex: 1;
  margin: 10px;
  padding-right: 10px;
  font-size: 18px;
  font-weight: normal;
  font-style: italic;
  /* animation: showContent 1s 1s linear 1 forwards; */
}

.qr-code h3 {
  margin-top: 10px; 
  margin-bottom: 20px;
}

.qr-code p{
  margin-top: 20px;
}

.qr-code p::first-letter{
  color: red;
}
.qr-code::first-letter {
  color: red;
}

.qr-code p {
  font-size: 16px;
}


.qr-code > div {
  width: 200px;
  height: 200px;
  /* animation: showContent 1s 1s linear 1 forwards; */
}

.contact-info {
  flex: 1;
  margin: 10px;
  padding-right: 10px;
  font-size: 22px;
  font-weight: normal;
  font-style: italic;
  /* animation: showThubmnail 0.5s linear 1 forwards; */
  /* animation: showContent 1s 1s linear 1 forwards; */
}

.contact-info p{
  flex: 1;
  padding-right: 10px;
  font-size: 14px;
  font-weight: normal;
  /* animation: showThubmnail 0.5s linear 1 forwards; */
  /* animation: showContent 1s 1s linear 1 forwards; */
}

/* .contact-info hr {
  border: 2px solid rgba(25, 23, 11, 0.353);
  border-radius: 5px;
} */


.contact-info hr{
  border: 1px solid rgba(5, 5, 5, 0.277);
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0;
}