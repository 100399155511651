.core-value-card {
    width: 300px;
    padding: 20px;
    margin: 20px;
    /* background-color: #f0f0f0; */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(77, 2, 2, 0.383);
  }
  
  .core-value-card h3 {
    margin-bottom: 10px;
  }
  
  .core-value-card ul {
    padding-left: 20px;
  }
  
  .core-value-card li {
    margin-bottom: 5px;
    font-weight: 100;
    text-align: left;
  }

  .core-value-card li::marker {
    color: red;
  }

  .core-values-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .core-value-card-title {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
  }

  .icon {
    margin-right: 10px;
    color:red;
  }